<template>
  <v-app-bar app color="background_sec">
    <!-- Hamburger menu icon for mobile -->
    <!-- Custom Hamburger/X Icon for Mobile -->
    <div class="toolbar-container">
      <v-btn icon @click.stop="drawer = !drawer" v-if="isMobile">
        <v-icon color="secondary" v-if="!drawer">mdi-menu</v-icon>
        <v-icon color="secondary" v-else>mdi-close</v-icon>
      </v-btn>

      <!-- Application title -->
      <v-toolbar-title>{{ $t('currentLanguage') }}</v-toolbar-title>

      <!-- Menu items for desktop -->
      <v-spacer></v-spacer>

      <!-- Register & Login -->
      <v-btn rounded="lg" variant="text" class="mr-2">{{ $t('login') }}</v-btn>
      <v-btn color="primary" rounded="lg" variant="elevated" to="/register">{{
        $t('register')
      }}</v-btn>

      <!-- Desktop Language Dropdown -->
      <language-dropdown v-if="!isMobile"></language-dropdown>
    </div>
    <!-- Additional Tabs for Desktop -->
    <template v-slot:extension v-if="!isMobile">
      <second-header></second-header>
    </template>
  </v-app-bar>

  <!-- Navigation Drawer for mobile -->
  <v-navigation-drawer color="background_sec" v-model="drawer" temporary>
    <v-list color="transparent">
      <v-list-item
        link
        v-for="item in menuItems"
        :key="item.title"
        :prepend-icon="item.icon"
        :title="$t(item.title)"
      >
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="text-center pa-5">
        <!-- Language Dropdown -->
        <language-dropdown></language-dropdown>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import LanguageDropdown from '../components/layouts/LanguageDropdown.vue'
import SecondHeader from '../components/layouts/SecondHeader.vue'
import { isMobile } from '@/composables/Helper'

export default {
  components: {
    LanguageDropdown,
    SecondHeader,
  },
  data() {
    return {
      drawer: false,
      menuItems: [
        { title: 'page1', icon: 'mdi-view-dashboard' },
        { title: 'page2', icon: 'mdi-account-box' },
        { title: 'page3', icon: 'mdi-gavel' },
        // ... other menu items ...
      ],
    }
  },
  computed: {
    isMobile() {
      return isMobile(this)
    },
  },
}
</script>

<style scoped lang="scss">
.v-btn {
  transition: opacity 0.2s ease-in-out;
}
.toolbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.7rem;
}
:deep(.v-list-item__prepend) {
  display: block;
}
@media (min-width: 1280px) {
  .toolbar-container {
    margin: 0 auto;
    width: 70%;
  }
  :deep(.v-toolbar__extension) {
    background-color: $background-third;
  }
  .v-tabs--align-tabs-title:not(.v-slide-group--has-affixes)
    .v-tab:first-child {
    margin-inline-start: 15px;
  }
}
</style>
