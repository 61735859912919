import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import router from './router'
// GLOBAL CSS
import './assets/scss/global.scss'

const app = createApp(App)

app.use(vuetify)
app.use(i18n)
app.use(router)

app.mount('#app')
