<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoadingScreen',
}
</script>

<style scoped>
.fill-height {
  height: 100%;
}
</style>
