// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark', // Set the default theme to 'dark'
    themes: {
      dark: {
        dark: true, // This ensures all components use the dark theme
        colors: {
          // SET COLOR HERE
          primary: '#692dd9',
          secondary: '#607fe2',
          third: '#9461f3',
          background: '#131523',
          background_sec: '#1c1e2e',
          background_third: '#252a40',
        },
      },
    },
  },
})

export default vuetify
