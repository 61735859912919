import { createI18n } from 'vue-i18n'

function loadLocaleMessages(locale) {
  return import(
    /* webpackChunkName: "locale-[request]" */ `../locales/${locale}.js`
  )
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {}, // Start with no messages
})

export default i18n

export async function loadLocale(locale) {
  if (!i18n.global.availableLocales.includes(locale)) {
    const messages = await loadLocaleMessages(locale)
    i18n.global.setLocaleMessage(locale, messages.default)
  }
  i18n.global.locale = locale
  document.querySelector('html').setAttribute('lang', locale)
}
