<template>
  <v-menu offset-y v-model="isDropdownOpen">
    <template v-slot:activator="{ props }">
      <v-btn
        variant="tonal"
        color="secondary"
        rounded
        v-bind="props"
        density="default"
        :class="menuStyle"
      >
        <v-img :src="currentFlag" height="25" width="25" class="mr-2" />
        <h3 v-if="isMobile">{{ $t('language') }}</h3>
        <v-icon right :class="{ 'rotate-icon': isDropdownOpen }">
          {{ chevronIcon }}
        </v-icon>
      </v-btn>
    </template>
    <v-list bg-color="background_third" :class="listBackground">
      <v-list-item @click="setLocale('en')">
        <template v-slot:prepend>
          <v-img
            :src="require('@/assets/images/flags/en.png')"
            height="25"
            width="25"
            class="mr-2"
          />
        </template>
        <v-list-item-title>ENGLISH</v-list-item-title>
      </v-list-item>
      <v-list-item @click="setLocale('cn')" class="d-flex align-center">
        <template v-slot:prepend>
          <v-img
            :src="require('@/assets/images/flags/cn.png')"
            height="25"
            width="25"
            class="mr-2"
          />
        </template>
        <v-list-item-title>简体中文</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { isMobile } from '@/composables/Helper'
import { loadLocale } from '@/plugins/i18n'

export default {
  data() {
    return {
      isDropdownOpen: false,
    }
  },
  computed: {
    currentFlag() {
      return this.$i18n.locale === 'en'
        ? require('@/assets/images/flags/en.png')
        : require('@/assets/images/flags/cn.png')
    },
    listBackground() {
      return isMobile(this) ? 'mb-3' : 'mt-4'
    },
    menuStyle() {
      return isMobile(this) ? '' : 'ml-3'
    },
    chevronIcon() {
      return isMobile(this) ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    isMobile() {
      return isMobile(this)
    },
  },
  methods: {
    async setLocale(locale) {
      try {
        await loadLocale(locale)
        localStorage.setItem('locale', locale) // Save user preference
      } catch (error) {
        console.error('Error loading locale:', error)
        // Handle error, maybe set a fallback locale
      } finally {
        this.isDropdownOpen = false // Close the dropdown
      }
    },
  },
}
</script>

<style scoped>
.rotate-icon {
  transition: transform 0.3s ease;
  transform: rotate(180deg); /* Rotate the icon when class is applied */
}
</style>
