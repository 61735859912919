<template>
  <v-app>
    <loading-screen v-if="isLoading" />
    <v-main v-else>
      <guest-layout></guest-layout>
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import GuestLayout from '@/layouts/GuestLayout'
import LoadingScreen from '@/components/shared/LoadingScreen'
import { loadLocale } from '@/plugins/i18n' // Import the loadLocale function

export default {
  name: 'App',
  components: {
    GuestLayout,
    LoadingScreen,
  },
  data() {
    return {
      isLoading: true, // Initial state for loading
    }
  },
  async created() {
    try {
      const locale = localStorage.getItem('locale') || 'en'
      await loadLocale(locale)
    } finally {
      this.isLoading = false // Hide loading screen after loading locale
    }
  },
}
</script>

<style scoped>
.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}
.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-active {
  transition: all 0.3s ease-in;
}
.route-enter-to,
.route-leave-to {
  opacity: 1;
  transform: translateY(0);
}
</style>
