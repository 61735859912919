import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/home/HomePage.vue'),
  },
  {
    path: '/slots',
    name: 'Slots',
    component: () => import('../pages/slots/SlotsPage.vue'),
  },
  {
    path: '/casino',
    name: 'Casino',
    component: () => import('../pages/casino/CasinoPage.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../pages/auth/UserRegister.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

export default router
