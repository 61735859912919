<template>
  <div class="toolbar-container">
    <div class="d-flex align-center" style="width: 100%">
      <!-- Left-aligned tabs -->
      <v-tabs v-model="tab" color="secondary" class="flex-grow-1">
        <v-tab
          v-for="item in leftMenuItems"
          :key="item.title"
          :value="item.title"
          :to="item.to"
        >
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>

      <!-- Right-aligned tabs -->
      <v-list-item link v-for="item in rightMenuItems" :key="item.title">
        <template v-slot:prepend>
          <v-icon :icon="item.icon" color="secondary"></v-icon>
        </template>
        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
      </v-list-item>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      leftMenuItems: [
        { title: 'Home', to: '/', name: 'Home' },
        { title: 'Slots', to: '/slots', name: 'Slots' },
        { title: 'Casino', to: '/casino', name: 'Casino' },
        // { title: 'Sports', to: '/' },
        // { title: 'Esports', to: '/' },
        // { title: 'Fishing', to: '/' },
        // { title: 'Lottery', to: '/' },
      ],
      rightMenuItems: [
        { title: 'Promotion', icon: 'mdi-gift-outline' },
        { title: 'VIP', icon: 'mdi-diamond-stone' },
        { title: 'Earn Money', icon: 'mdi-cash' },
      ],
    }
  },
  mounted() {
    this.syncTabWithRoute()
  },
  watch: {
    // Watch for route changes
    $route() {
      this.syncTabWithRoute()
    },
  },
  methods: {
    syncTabWithRoute() {
      // Find the tab that matches the current route
      const currentTab = this.leftMenuItems.find(
        (item) => item.to === this.$route.path
      )
      this.tab = currentTab ? currentTab.name : null
    },
  },
}
</script>

<style lang="scss" scoped></style>
